<!-- 系统设置-角色管理 -->
<template>
    <div class="content-main">
        <div v-if="!errorShow">
            <loading :loadingShow="loadingShow"></loading>
            <div v-if="!loadingShow">
                <div class="filter-wrap">
                    <expand-filter
                        :closeWidth="170"
                        :formData="formData"
                        marginBottom="0px"
                        @changeBtnFormType="changeBtnFormType"
                        @clickBtn="clickBtn"
                    ></expand-filter>
                    <div
                        class="button-line"
                        v-hasPermi="['role:add']"
                    ></div>
                    <el-button
                        v-hasPermi="['role:add']"
                        type="primary"
                        @click="add"
                        >添加</el-button
                    >
                </div>

                <table-data
                    v-loading="loadingTable"
                    ref="table"
                    :config="table_config"
                    :tableData="table_data"
                    :backgroundHeader="backgroundHeader"
                    :headerColor="headerColor"
                >
                    <template v-slot:operation="slotData">
                        <el-button
                            type="text"
                            class="primary"
                            @click="edit(slotData.data)"
                            style="font-size: 14px"
                            v-hasPermi="['role:edit']"
                            >编辑
                        </el-button>
                        <el-button
                            v-if="slotData.data.isDefaultTeacher !== 1"
                            type="text"
                            class="danger"
                            @click="del(slotData.data)"
                            v-hasPermi="['role:delete']"
                            >删除
                        </el-button>
                    </template>
                </table-data>
                <Pagination
                    :total="total"
                    :page.sync="listQuery.pageNum"
                    :limit.sync="listQuery.pageRow"
                    @pagination="getList"
                />

                <dialog-wrapper
                    :dialogObj="dialogObj"
                    @handleClose="handleClose"
                >
                    <div style="height: 500px; overflow: hidden">
                        <div
                            style="
                                height: 450px;
                                overflow: hidden;
                                overflow-y: auto;
                                padding-right: 10px;
                                margin-bottom: 10px;
                            "
                        >
                            <el-form
                                :model="ruleForm"
                                :rules="rules"
                                ref="ruleForm"
                                label-width="130px"
                                class="common-form"
                                :key="dialogObjKeys"
                            >
                                <el-form-item
                                    label="角色名称"
                                    prop="roleName"
                                >
                                    <el-input
                                        v-model="ruleForm.roleName"
                                        maxlength="20"
                                        show-word-limit
                                        clearable
                                        placeholder="请输入角色名称"
                                        class="input-width-medium"
                                    ></el-input>
                                </el-form-item>
                                <el-form-item
                                    label="角色组权限"
                                    prop="permissionIds"
                                >
                                    <el-tree
                                        ref="treeRef"
                                        :data="permissionList"
                                        show-checkbox
                                        node-key="menuId"
                                        :props="{
                                            children: 'children',
                                            label: 'menuName',
                                        }"
                                        @check="handleCheck('per', $event)"
                                    >
                                        <span
                                            class="custom-tree-node"
                                            slot-scope="{data}"
                                        >
                                            <span>{{ data.menuName }}</span>
                                            <span v-if="handleShow(data)">
                                                <span class="custom-tree-tip"
                                                    >权限细分</span
                                                >
                                                <span
                                                    class="custom-tree-switch"
                                                >
                                                    <el-switch
                                                        class="custom-switch"
                                                        :key="switchKey"
                                                        :width="34"
                                                        v-model="
                                                            data.openSwitch
                                                        "
                                                        :active-value="true"
                                                        :inactive-value="false"
                                                        @change="
                                                            switchOpenOrClose(
                                                                data,
                                                            )
                                                        "
                                                    >
                                                    </el-switch>
                                                </span>
                                                <span
                                                    v-if="data.openSwitch"
                                                    class="goto-set-class"
                                                    @click="
                                                        gotoSetPermission(data)
                                                    "
                                                    >去设置</span
                                                >
                                            </span>
                                        </span>
                                    </el-tree>
                                </el-form-item>
                                <el-form-item
                                    label="app角色组权限"
                                    prop="appPermissionIds"
                                >
                                    <el-tree
                                        ref="treeRef1"
                                        :data="appTreeData"
                                        show-checkbox
                                        node-key="menuId"
                                        :props="{
                                            children: 'children',
                                            label: 'menuName',
                                        }"
                                        @check="handleCheck('appPer', $event)"
                                    />
                                </el-form-item>
                            </el-form>
                        </div>
                        <div :style="{'text-align': 'right'}">
                            <el-button
                                type="enquiry"
                                @click="handleClose"
                                >取消</el-button
                            >
                            <el-button
                                type="primary"
                                :loading="btnLoading"
                                @click="submit"
                            >
                                确定
                            </el-button>
                        </div>
                    </div>
                </dialog-wrapper>
                <dialog-wrapper
                    :dialogObj="setDialogObj"
                    @handleClose="setDialogObjhandleClose"
                >
                    <div class="push_table">
                        <el-descriptions
                            :column="2"
                            border
                            v-for="(item, index) in setTableData"
                            :key="index"
                        >
                            <el-descriptions-item
                                label-class-name="no-brackets"
                                content-class-name="no-my-content1"
                                v-if="item.firstlable == '授权'"
                                :span="2"
                            >
                                <span
                                    class="left_no_lable1"
                                    v-if="item.firstlable == '授权'"
                                    >权限名称</span
                                >
                                <span
                                    class="right_no_lable1"
                                    v-if="item.firstlable == '授权'"
                                >
                                    授权
                                </span>
                            </el-descriptions-item>
                            <el-descriptions-item
                                label-class-name="no-brackets"
                                content-class-name="no-my-content2"
                                v-if="item.firstlable == '基础信息'"
                                :span="2"
                            >
                                <span
                                    class="left_no_lable2"
                                    v-if="item.firstlable == '基础信息'"
                                    >基础信息</span
                                >
                                <span
                                    class="right_no_lable2"
                                    v-if="item.firstlable == '基础信息'"
                                >
                                    <el-checkbox
                                        v-model="item.pushSwitch"
                                        @change="handlePushChecked(item)"
                                    ></el-checkbox>
                                </span>
                            </el-descriptions-item>
                            <el-descriptions-item
                                label-class-name="no-brackets"
                                content-class-name="no-my-content3"
                                v-if="item.firstlable == '更多信息'"
                                :span="2"
                            >
                                <span
                                    class="left_no_lable3"
                                    v-if="item.firstlable == '更多信息'"
                                    >更多信息</span
                                >
                                <span
                                    class="right_no_lable3"
                                    v-if="item.firstlable == '更多信息'"
                                >
                                    <el-checkbox
                                        :indeterminate="isIndeterminateMessage"
                                        v-model="item.pushSwitch"
                                        @change="handlePushChecked(item)"
                                    ></el-checkbox>
                                </span>
                            </el-descriptions-item>
                            <el-descriptions-item
                                v-else-if="
                                    item.firstlable != '授权' &&
                                    item.firstlable != '基础信息' &&
                                    item.firstlable != '更多信息'
                                "
                                label-class-name="each-brackets"
                                content-class-name="each-my-content"
                                :label="item.segmentMenuName"
                                :span="2"
                            >
                                <span class="right_no_lable4">
                                    <el-checkbox
                                        v-model="item.pushSwitch"
                                        @change="handlePushChecked(item)"
                                    ></el-checkbox>
                                </span>
                            </el-descriptions-item>
                        </el-descriptions>
                        <div
                            slot="append"
                            class="more-box"
                        ></div>
                    </div>
                    <div class="bottom_box">
                        <el-button
                            type="enquiry"
                            @click="setDialogObjhandleClose"
                            >取消</el-button
                        >
                        <el-button
                            type="primary"
                            @click="submitSetPermission"
                        >
                            确定
                        </el-button>
                    </div>
                </dialog-wrapper>
            </div>
        </div>
        <error
            v-else
            :errorShow="errorShow"
        ></error>
    </div>
</template>

<script>
import {
    // 业务组件
    Pagination,
    // 功能组件
    Loading,
    Error,
    DialogWrapper,
    // 工具函数
    debounce,
} from "common-local";
import TableData from "./Sub/TableData";
import {SystemModel} from "@/models/System.js";
import {mapState} from "vuex";
import {treeFilter, treeFind, treeToList} from "@/libs/utils";
import ExpandFilter from "./Sub/ExpandFilter";
import onResize from "@/mixins/onResize";
import {CampusSafetyModel} from "@/models/CampusSafety";

export default {
    mixins: [onResize],
    name: "systemRoleScrollWrapper",
    components: {
        ExpandFilter,
        TableData,
        Pagination,
        DialogWrapper,
        Loading,
        Error,
    },
    data() {
        return {
            btnLoading: false,
            treeData: [],
            permissionList: [],
            appTreeData: [],
            ruleForm: {
                roleName: "",
                id: "",
                permissionIds: [],
                appPermissionIds: [],
                segmentSwitchIds: [], // 开启权限细分按钮的菜单
                segmentPermissions: [], // 勾选对应的权限
            },
            rules: {
                roleName: [
                    {
                        required: true,
                        message: "请输入角色名称",
                        trigger: "blur",
                    },
                ],
                permissionIds: [
                    {
                        validator: (rule, value, callback) => {
                            if (
                                this.ruleForm.permissionIds.length === 0 &&
                                this.ruleForm.appPermissionIds.length === 0
                            ) {
                                callback("请选择角色组权限");
                            } else {
                                callback();
                            }
                        },
                        required: true,
                        trigger: "blur",
                    },
                ],
                appPermissionIds: [
                    {
                        validator: (rule, value, callback) => {
                            if (
                                this.ruleForm.permissionIds.length === 0 &&
                                this.ruleForm.appPermissionIds.length === 0
                            ) {
                                callback("请选择角色组权限");
                            } else {
                                callback();
                            }
                        },
                        required: true,
                        trigger: "blur",
                    },
                ],
            },
            // 头部筛选
            formData: {
                formInline: {
                    roleName: "",
                },
                data: [
                    {
                        type: "input",
                        label: "",
                        value: "",
                        placeholder: "角色名称",
                        key: "roleName",
                    },
                ],
                btnList: [
                    {
                        type: "primary",
                        text: "查询",
                        fn: "primary",
                        auth: ["role:list"],
                    },
                ],
            },

            // 表格配置
            table_config: {
                thead: [
                    {
                        label: "角色名称",
                        prop: "roleName",
                        align: "center",
                    },
                    {
                        label: "创建人",
                        prop: "createByName",
                        align: "center",
                    },
                    {
                        label: "创建时间",
                        prop: "createTime",
                        type: "function",
                        align: "center",
                        callBack: (row) => {
                            return row.createTime
                                ? row.createTime.slice(0, -3)
                                : "-";
                        },
                    },
                    {
                        label: "操作",
                        type: "slot",
                        slotName: "operation",
                        className: "text-spacing-reduction",
                    },
                ],
                check: false,
                height: "",
            },
            // 表格数据
            table_data: [],
            // 分页器
            total: 0,
            listQuery: {
                pageNum: 1,
                pageRow: 20,
                roleName: "",
            },

            // 功能组件
            errorShow: false,
            loadingShow: false,
            loadingTable: true,

            // 表头
            backgroundHeader: "#FAFBFC",
            // 表头字体
            headerColor: "",
            template_info: [],
            // 按钮字体颜色
            btnColor: "#ffffff",
            // 弹出框配置
            dialogObj: {
                title: "",
                dialogVisible: false,
                width: "auto",
            },
            schoolId: "",
            // 去设置弹出框配置
            setDialogObj: {
                title: "",
                dialogVisible: false,
                width: "680px",
            },
            setLoadingTable: true,
            selectDataMessage: [], // 选择后的数据
            checkedAll: false, // 全选
            isIndeterminateMessage: null,
            setTotal: 0,
            setTableData: [],
            getTableData: [],
            peopleSwitchArr: [], // 勾选
            isShowSwitch: false, // 是否展示权限细分开关
            checkPerssionList: [], // 勾选的菜单
            switchKey: 1,
            dialogObjKeys: 1,
            checkIdsList: [], // 权限细分 编辑 勾选的菜单id
            isEdit: false, // 是否编辑
            editObj: {}, // 是否编辑
            curMenuId: "", // 当前菜单id
            sessionArr: [], // 权限细分暂存的勾选数据
        };
    },
    computed: {
        ...mapState({
            templateInfo: (state) => state.templateInfo,
        }),
    },
    created() {
        this.init();
    },
    mounted() {},
    methods: {
        arraySpanMethod({row, column, rowIndex, columnIndex}) {
            if (rowIndex === 0 && columnIndex === 0) {
                return [2, 1]; // 合并两行一列
            }
        },
        init() {
            this.schoolId = this.$store.state.schoolId;
            this.getList();
            this.getMenusTree();
            this.getAppMenusTree();
        },
        initTabColor() {
            this.template_info = this.templateInfo;
            if (this.template_info && this.template_info.length > 0) {
                this.template_info.forEach((p, i) => {
                    if (p.sel) {
                        this.headerColor = p.textColor;
                    }
                });
            }
        },
        add() {
            this.getMenusTree();
            this.dialogObjKeys += 1;
            this.dialogObj.title = "新增角色";
            this.dialogObj.dialogVisible = true;
            this.permissionList = this.treeData;
            this.checkPerssionList = [];
            this.isEdit = false;
        },
        // 编辑
        edit(data) {
            this.editObj = data;
            this.isEdit = true;
            this.dialogObjKeys += 1;
            this.ruleForm.id = data.roleId;
            this.ruleForm.roleName = data.roleName;
            this.ruleForm.permissionIds = data.permissionIds || [];
            this.checkPerssionList = data.permissionIds || [];
            this.ruleForm.appPermissionIds = data.appPermissionIds || [];
            this.ruleForm.segmentSwitchIds = data.segmentSwitchIds || [];
            this.getSegmentPermissions(data); // 获取权限细分
            // 普通用户角色-权限范围：除系统设置外所有模块及按钮
            if (data.isDefaultTeacher === 1) {
                const systemMenuId = "090000";
                this.permissionList = treeFilter(
                    this.treeData,
                    "menuId",
                    systemMenuId,
                    "children",
                );
            } else {
                this.permissionList = this.treeData;
            }
            this.dialogObj.title = "编辑角色";
            this.dialogObj.dialogVisible = true;
            this.getCheckRoleList(data);
        },
        /**
         * @Description: 获取当前角色的权限细分数据
         * @Author: 闫乔
         * @Date: 2024-09-01 15:51:42
         */
        getSegmentPermissions(data) {
            data.segmentPermissionIds.forEach((item) => {
                item.perId = item.menuId + item.segmentMenuId;
            });
            this.checkIdsList = data.segmentPermissionIds || [];
        },
        /**
         * @Description: 获取某个模块下的人员列表
         * @Author: 闫乔
         * @Date: 2024-08-29 17:02:22
         */
        async getCheckRoleList(data) {
            if (data.segmentSwitchIds && data.segmentSwitchIds.length > 0) {
                data.segmentSwitchIds.forEach((item) => {
                    if (item) {
                        let currentObj = treeFind(
                            this.permissionList,
                            (e) => e.menuId == item,
                        );
                        this.$set(currentObj, "openSwitch", true);
                    }
                });
            }
        },
        del(data) {
            this.$confirm("此操作将永久删除, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    const systemModel = new SystemModel();
                    systemModel
                        .delRole({
                            roleVOList: [
                                {
                                    id: data.roleId,
                                },
                            ],
                        })
                        .then((res) => {
                            this.handleRes(res, () => {
                                this.$message.success("删除成功!");
                                this.getList();
                            });
                        });
                })
                .catch(() => {});
        },
        clickBtn(ev) {
            switch (ev.item.fn) {
                case "primary":
                    this.formData.data.forEach((item) => {
                        this.listQuery[item.key] = item.value;
                    });
                    this.getList(1);
                    break;
                case "reset":
                    this.formData.data.forEach((item) => {
                        item.value = "";
                        this.listQuery[item.key] = "";
                    });
                    this.getList(1);
                    break;
                case "add":
                    this.add();
                    break;
                default:
                    break;
            }
        },
        /**
         * @Description: 是否展示开关
         * @Author: 闫乔
         * @Date: 2024-08-27 09:54:17
         * @param {*} event
         */
        handleShow(data) {
            if (data.permSegmentDisplay != "1") {
                return false;
            }
            return this.checkPerssionList.includes(data.menuId);
        },
        handleCheck(e, ev) {
            if (e == "per") {
                let cur = ev;
                let currentObj = treeFind(
                    this.permissionList,
                    (e) => e.menuId == cur.menuId,
                );
                this.$set(currentObj, "openSwitch", false);
                this.switchKey += 1;
                this.checkPerssionList = this.$refs.treeRef.getCheckedKeys();
            }
            this.ruleForm.permissionIds = [
                ...this.$refs.treeRef.getCheckedKeys(),
                ...this.$refs.treeRef.getHalfCheckedKeys(),
            ];
            this.ruleForm.appPermissionIds = [
                ...this.$refs.treeRef1.getCheckedKeys(),
                ...this.$refs.treeRef1.getHalfCheckedKeys(),
            ];
        },
        /**
         * @Description: 修改某个模块的开关按钮
         * @Author: 闫乔
         * @Date: 2024-08-22 18:30:49
         * @param {*} data
         */
        switchOpenOrClose(data) {
            if (data.openSwitch) {
                this.$set(data, "defaultSwitch", true);
            } else {
                this.$set(data, "defaultSwitch", false);
            }
            this.switchKey += 1;
        },
        /**
         * @Description: 点击去设置按钮
         * @Author: 闫乔
         * @Date: 2024-08-24 11:00:07
         */
        async gotoSetPermission(data) {
            this.curMenuId = data.menuId;
            if (data.menuId == "0304010106") {
                this.setDialogObj.title = "编辑权限细分";
            } else {
                this.setDialogObj.title = "查看权限细分";
            }
            await this.getPeopleList(data);
            this.setDialogObj.dialogVisible = true;
        },
        /**
         * @Description: 权限细分弹窗关闭
         * @Author: 闫乔
         * @Date: 2024-08-24 11:04:31
         */
        setDialogObjhandleClose() {
            this.setDialogObj.dialogVisible = false;
        },
        /**
         * @Description: 权限细分弹窗的确定
         * @Author: 闫乔
         * @Date: 2024-08-24 11:09:14
         */
        submitSetPermission: debounce(function () {
            let aaa = [];
            if (this.setTableData && this.setTableData.length > 0) {
                let checkList = this.setTableData.filter(
                    (item) => item.id != "firsttitle" && item.pushSwitch,
                );
                if (checkList && checkList.length > 0) {
                    aaa = checkList.map((item) => {
                        return {
                            schoolId: this.$store.state.schoolId,
                            menuId: item.menuId,
                            segmentMenuId: item.id,
                            parentId: item.parentId,
                            perId: item.menuId + item.id,
                        };
                    });
                    if (this.isEdit) {
                        aaa = aaa.map((item) => {
                            return {
                                ...item,
                                roleId: this.ruleForm.id,
                            };
                        });
                    }
                }
            }
            if (this.curMenuId) {
                this.checkIdsList = this.checkIdsList.filter(
                    (item) => item.menuId != this.curMenuId,
                );
            }
            if (aaa && aaa.length > 0) {
                this.checkIdsList.push(...aaa);
            }
            // 1.拼接数据结构，{id:...,list:[]}
            // 2.检查新数组，有，替换；无，push，（根据id）
            let newAr = this.checkIdsList.filter(
                (item) => item.menuId == this.curMenuId,
            );
            let newObj = {
                id: this.curMenuId,
                list: JSON.parse(JSON.stringify(newAr)),
            };
            let someItem = this.sessionArr.find(
                (items) => items.id == newObj.id,
            );
            if (someItem) {
                this.sessionArr = this.sessionArr.map((item) => {
                    if (item.id == newObj.id) {
                        return newObj;
                    } else {
                        return item;
                    }
                });
            } else {
                this.sessionArr.push(newObj);
            }
            let currentObj = treeFind(
                this.permissionList,
                (e) => e.menuId == this.curMenuId,
            );
            this.$set(currentObj, "defaultSwitch", false);
            this.setDialogObjhandleClose();
        }, 100),
        /**
         * @Description: 处理保存前的参数处理
         * @Author: 闫乔
         * @Date: 2024-08-29 15:13:43
         */
        getHandelSwitch() {
            let newArray = [];
            this.checkPerssionList.forEach((item) => {
                let hasObj = treeFind(
                    this.permissionList,
                    (e) => e.menuId == item,
                );
                newArray.push(hasObj);
            });

            if (newArray && newArray.length > 0) {
                newArray = newArray.filter((item) => {
                    return (
                        item?.permSegmentDisplay &&
                        item?.permSegmentDisplay == "1" &&
                        item?.openSwitch
                    );
                });
                let switchIds = [];
                if (newArray && newArray.length > 0) {
                    switchIds = newArray.map((item) => {
                        return item.menuId;
                    });
                }
                this.ruleForm.segmentSwitchIds = switchIds;
            }

            // 处理权限细分权限勾选参数
            if (
                this.ruleForm.segmentSwitchIds &&
                this.ruleForm.segmentSwitchIds.length == 0
            ) {
                this.ruleForm.segmentPermissions = [];
            } else {
                if (this.checkIdsList && this.checkIdsList.length > 0) {
                    let mapFn = new Map();
                    this.ruleForm.segmentPermissions = this.checkIdsList.filter(
                        (a) => !mapFn.has(a.perId) && mapFn.set(a.perId, 1),
                    );
                }
            }
            // 去重
            this.ruleForm.permissionIds = [
                ...new Set(this.ruleForm.permissionIds),
            ];
            this.ruleForm.appPermissionIds = [
                ...new Set(this.ruleForm.appPermissionIds),
            ];
            this.ruleForm.segmentSwitchIds = [
                ...new Set(this.ruleForm.segmentSwitchIds),
            ];
        },
        /**
         * @Description: 角色管理的保存
         * @Author: 闫乔
         * @Date: 2024-08-29 15:14:17
         */
        submit() {
            this.$refs.ruleForm.validate((val) => {
                if (val) {
                    this.btnLoading = true;
                    this.getHandelSwitch();
                    if (this.dialogObj.title === "新增角色") {
                        this._fet("/school/schoolRole/insert", this.ruleForm)
                            .then((res) => {
                                this.handleRes(res, () => {
                                    this.$message.success(
                                        `${this.dialogObj.title}成功`,
                                    );
                                    this.handleClose();
                                    this.getList();
                                });
                            })
                            .finally(() => {
                                this.btnLoading = false;
                            });
                    } else {
                        this._fet("/school/schoolRole/edit", this.ruleForm)
                            .then((res) => {
                                this.handleRes(res, () => {
                                    this.$message.success(
                                        `${this.dialogObj.title}成功`,
                                    );
                                    this.handleClose();
                                    this.getList();
                                });
                            })
                            .finally(() => {
                                this.btnLoading = false;
                            });
                    }
                }
            });
        },
        // table 分页器
        getList(t) {
            if (t === 1) {
                this.listQuery.pageNum = 1;
            }
            this.loadingTable = true;
            this._fet("/school/schoolRole/listAllRole", this.listQuery).then(
                (res) => {
                    this.handleRes(res, () => {
                        this.table_data = res.data.data.list;
                        this.total = res.data.data.totalCount;
                        this.loadingTable = false;
                    });
                },
            );
        },
        getMenusTree() {
            this._fet(
                "/school/common/getMenuTree?belongPlat=2&schoolId=" +
                    this.schoolId,
            ).then((res) => {
                this.handleRes(res, () => {
                    res.data.data.data.forEach((item) => {
                        item.openSwitch = false; // 权限开关是否开启
                        item.defaultSwitch = false; // 是否默认全选
                    });
                    // 过滤 学生档案详情/学生档案模板/档案分享
                    const hideMenus = ["030403", "030404", "030405"];
                    hideMenus.forEach((menuId) => {
                        this.treeData = treeFilter(
                            res.data.data.data,
                            "menuId",
                            menuId,
                            "children",
                        );
                    });
                });
            });
        },
        getAppMenusTree() {
            this._fet(
                "/school/common/getMenuTree?belongPlat=3&schoolId=" +
                    this.schoolId,
            ).then((res) => {
                this.handleRes(res, () => {
                    this.appTreeData = res.data.data.data;
                });
            });
        },
        handleClose() {
            this.dialogObj.dialogVisible = false;
            this.$refs.ruleForm.clearValidate(); // 清空校验
            this.ruleForm = this.$options.data().ruleForm;
            this.sessionArr = []; // 清空缓存的勾选数据
        },
        // 接口请求结果处理
        handleRes(res, fn) {
            if (res.data.code === "200") {
                fn();
            } else if (res.data && res.data.msg) {
                this.$message.error(res.data.msg);
            }
        },
        /**
         * @Description: 获取更多信息的权限按钮
         * @Author: 闫乔
         * @Date: 2024-08-24 11:47:40
         */
        async getPeopleList(data) {
            const campusSafety = new CampusSafetyModel();
            await campusSafety
                .segemtConditionMenuTreeList({
                    schoolId: this.schoolId,
                    belongPlat: "2",
                })
                .then((res) => {
                    if (res.data.code === "200") {
                        this.checkedAll = false;
                        this.isIndeterminateMessage = true;
                        let myTreeData = res.data.data;
                        this.selectDataMessage = [];
                        this.setTableData = [];
                        let curTreeList = [];
                        if (myTreeData && myTreeData.length > 0) {
                            myTreeData.find((item) => {
                                if (item.menuId == data.menuId) {
                                    curTreeList = item.children;
                                }
                            });
                            // 拼接数据
                            let getMyTreeList = treeToList(
                                this._.cloneDeep(curTreeList),
                                "children",
                            );
                            let firstObj = {
                                name: "权限名称",
                                id: "firsttitle",
                                firstlable: "授权",
                            };
                            getMyTreeList.unshift(firstObj);
                            getMyTreeList.forEach((item) => {
                                item.pushSwitch = false;
                                if (item.segmentMenuName == "基础信息") {
                                    item.firstlable = "基础信息";
                                } else if (item.segmentMenuName == "更多信息") {
                                    item.firstlable = "更多信息";
                                }
                            });
                            let normalArrays = getMyTreeList.filter(
                                (item) => !item.firstlable,
                            );
                            if (normalArrays && normalArrays.length == 0) {
                                // 去掉更多信息的选项
                                let moreIndex = getMyTreeList.findIndex(
                                    (item) => item.firstlable == "更多信息",
                                );
                                getMyTreeList[moreIndex].pushSwitch = false;
                                getMyTreeList.splice(moreIndex, 1);
                            }

                            this.setTableData = getMyTreeList;
                            this.getTableData = getMyTreeList;

                            // 判断，是否默认全选
                            if (data.defaultSwitch) {
                                this.setTableData.forEach((i) => {
                                    this.$set(i, "pushSwitch", true);
                                    this.selectDataMessage.push(i);
                                });
                            } else {
                                // 回显表格上次缓存的勾选状态
                                if (
                                    this.sessionArr &&
                                    this.sessionArr.length > 0
                                ) {
                                    let seddionList = this.sessionArr.filter(
                                        (im) => im.id == data.menuId,
                                    );
                                    if (seddionList && seddionList.length > 0) {
                                        let sessionCheckList =
                                            seddionList[0].list || [];
                                        this.peopleSwitchArr = [];
                                        if (
                                            this.getTableData &&
                                            this.getTableData.length > 0
                                        ) {
                                            this.getTableData.forEach(
                                                (subm) => {
                                                    if (
                                                        sessionCheckList &&
                                                        sessionCheckList.length >
                                                            0
                                                    ) {
                                                        sessionCheckList.forEach(
                                                            (its) => {
                                                                if (its) {
                                                                    if (
                                                                        its.segmentMenuId ==
                                                                        subm.id
                                                                    ) {
                                                                        subm.pushSwitch = true;
                                                                        this.peopleSwitchArr.push(
                                                                            subm.id,
                                                                        );
                                                                    }
                                                                }
                                                            },
                                                        );
                                                    }
                                                },
                                            );
                                        }
                                        this.setTableData = this.getTableData;

                                        this.setTableData.forEach((i) => {
                                            this.peopleSwitchArr.forEach(
                                                (vi) => {
                                                    if (i.id == vi) {
                                                        this.$set(
                                                            i,
                                                            "pushSwitch",
                                                            true,
                                                        );
                                                        this.selectDataMessage.push(
                                                            i,
                                                        );
                                                    }
                                                },
                                            );
                                        });
                                    } else {
                                        // 如果没有缓存的
                                        // 如果是编辑
                                        if (this.isEdit) {
                                            // 回显表格勾选状态
                                            let archivesList =
                                                this.editObj
                                                    .segmentPermissionIds || [];
                                            let curCheckList = [];
                                            curCheckList = archivesList.filter(
                                                (item) =>
                                                    item.menuId == data.menuId,
                                            );
                                            this.peopleSwitchArr = [];
                                            if (
                                                this.getTableData &&
                                                this.getTableData.length > 0
                                            ) {
                                                this.getTableData.forEach(
                                                    (subItem) => {
                                                        if (
                                                            curCheckList &&
                                                            curCheckList.length >
                                                                0
                                                        ) {
                                                            curCheckList.forEach(
                                                                (it) => {
                                                                    if (it) {
                                                                        if (
                                                                            it.segmentMenuId ==
                                                                            subItem.id
                                                                        ) {
                                                                            subItem.pushSwitch = true;
                                                                            this.peopleSwitchArr.push(
                                                                                subItem.id,
                                                                            );
                                                                        }
                                                                    }
                                                                },
                                                            );
                                                        }
                                                    },
                                                );
                                            }
                                            this.setTableData =
                                                this.getTableData;

                                            this.setTableData.forEach((i) => {
                                                this.peopleSwitchArr.forEach(
                                                    (vi) => {
                                                        if (i.id == vi) {
                                                            this.$set(
                                                                i,
                                                                "pushSwitch",
                                                                true,
                                                            );
                                                            this.selectDataMessage.push(
                                                                i,
                                                            );
                                                        }
                                                    },
                                                );
                                            });
                                        }
                                    }
                                } else {
                                    // 如果没有缓存的
                                    // 如果是编辑
                                    if (this.isEdit) {
                                        // 回显表格勾选状态
                                        let archivesList =
                                            this.editObj.segmentPermissionIds ||
                                            [];
                                        let curCheckList = [];
                                        curCheckList = archivesList.filter(
                                            (item) =>
                                                item.menuId == data.menuId,
                                        );
                                        this.peopleSwitchArr = [];
                                        if (
                                            this.getTableData &&
                                            this.getTableData.length > 0
                                        ) {
                                            this.getTableData.forEach(
                                                (subItem) => {
                                                    if (
                                                        curCheckList &&
                                                        curCheckList.length > 0
                                                    ) {
                                                        curCheckList.forEach(
                                                            (it) => {
                                                                if (it) {
                                                                    if (
                                                                        it.segmentMenuId ==
                                                                        subItem.id
                                                                    ) {
                                                                        subItem.pushSwitch = true;
                                                                        this.peopleSwitchArr.push(
                                                                            subItem.id,
                                                                        );
                                                                    }
                                                                }
                                                            },
                                                        );
                                                    }
                                                },
                                            );
                                        }
                                        this.setTableData = this.getTableData;

                                        this.setTableData.forEach((i) => {
                                            this.peopleSwitchArr.forEach(
                                                (vi) => {
                                                    if (i.id == vi) {
                                                        this.$set(
                                                            i,
                                                            "pushSwitch",
                                                            true,
                                                        );
                                                        this.selectDataMessage.push(
                                                            i,
                                                        );
                                                    }
                                                },
                                            );
                                        });
                                    }
                                }
                            }
                            // 过滤掉更多信息的勾选，判断勾选的子级长度，进行全选/半选判断
                            this.selectDataMessage =
                                this.selectDataMessage.filter(
                                    (item) => !item.firstlable,
                                );
                            // 如果子级都没选，更多信息也不勾选
                            if (this.selectDataMessage.length == 0) {
                                this.isIndeterminateMessage = null;
                            }
                            // 处理全选/半选状态
                            let normalList = this.setTableData.filter(
                                (item) => !item.firstlable,
                            );
                            this.checkedAll =
                                this.selectDataMessage.length ===
                                normalList.length;
                            this.isIndeterminateMessage =
                                this.selectDataMessage.length > 0 &&
                                this.selectDataMessage.length <
                                    normalList.length;
                            if (
                                this.selectDataMessage.length > 0 &&
                                this.selectDataMessage.length ==
                                    normalList.length
                            ) {
                                this.isIndeterminateMessage = false;
                            }
                        }
                    } else if (res.data && res.data.msg) {
                        this.$message.error(res.data.msg);
                    }
                    this.setLoadingTable = false;
                })
                .catch(() => {
                    this.setLoadingTable = false;
                });
        },
        /**
         * @Description: 更多信息全选
         * @Author: 闫乔
         * @Date: 2023-08-10 16:19:32
         */
        handlePushAll(val) {
            this.checkedAll = val;
            this.selectDataMessage = [];
            this.setTableData.forEach((item) => {
                if (!item.firstlable) {
                    if (this.checkedAll) {
                        item.pushSwitch = true;
                        this.$set(item, "pushSwitch", true);
                        this.selectDataMessage.push(item);
                    } else {
                        item.pushSwitch = false;
                        this.selectDataMessage.push(item);
                    }
                }
            });
            this.isIndeterminateMessage = false;
        },
        /**
         * @Description: 更多的单选
         * @Author: 闫乔
         * @Date: 2023-08-10 16:19:32
         */
        handlePushChecked(rows) {
            // 判断是‘基础信息’还是‘更多信息’
            if (rows.segmentMenuName == "基础信息") {
                // 基础信息
            } else if (rows.segmentMenuName == "更多信息") {
                // 更多信息
                this.handlePushAll(rows.pushSwitch);
            } else {
                this.checkedAll = false;
                let normalList = this.setTableData.filter(
                    (item) => !item.firstlable,
                );
                if (normalList.length == 0) {
                    this.checkedAll = false;
                    this.isIndeterminateMessage = null;
                } else {
                    this.selectDataMessage = [];
                    normalList.forEach((item) => {
                        if (item.pushSwitch) {
                            this.selectDataMessage.push(item);
                        }
                    });
                    if (this.selectDataMessage.length > 0) {
                        this.setTableData.forEach((item) => {
                            if (item.firstlable == "更多信息") {
                                item.pushSwitch = true;
                            }
                        });
                    } else {
                        this.setTableData.forEach((item) => {
                            if (item.firstlable == "更多信息") {
                                item.pushSwitch = false;
                            }
                        });
                    }
                    this.checkedAll =
                        this.selectDataMessage.length === normalList.length;
                    this.isIndeterminateMessage =
                        this.selectDataMessage.length > 0 &&
                        this.selectDataMessage.length < normalList.length;
                }
                this.$forceUpdate();
            }
        },
    },
    watch: {
        templateInfo: {
            handler() {
                this.initTabColor();
            },
            deep: true,
            immediate: true,
        },
        "ruleForm.permissionIds": {
            handler(data) {
                this.$nextTick(() => {
                    if (this.$refs.treeRef) {
                        if (data.length === 0) {
                            this.$refs.treeRef.setCheckedKeys([]);
                        } else {
                            data.forEach((item) => {
                                const node = this.$refs.treeRef.getNode(item);
                                if (node && node.isLeaf) {
                                    this.$refs.treeRef.setChecked(node, true);
                                }
                            });
                        }
                    }
                });
            },
            immediate: true,
            deep: true,
        },
        "ruleForm.appPermissionIds": {
            handler(data) {
                this.$nextTick(() => {
                    if (this.$refs.treeRef1) {
                        if (data.length === 0) {
                            this.$refs.treeRef1.setCheckedKeys([]);
                        } else {
                            data.forEach((item) => {
                                const node = this.$refs.treeRef1.getNode(item);
                                if (node && node.isLeaf) {
                                    this.$refs.treeRef1.setChecked(node, true);
                                }
                            });
                        }
                    }
                });
            },
            immediate: true,
            deep: true,
        },
    },
};
</script>

<style lang="scss" scoped>
.filter-wrap {
    display: flex;
    text-align: center;
    align-items: flex-start;
    background-color: #fff;
    padding: 10px 20px 0;
    margin-bottom: 10px;
    border-radius: 4px;
    overflow: hidden;

    .expand-filter {
        padding: 0;
    }
}
.goto-set-class {
    color: #3c7efe;
    cursor: pointer;
    margin-left: 8px;
}
.custom-tree-tip {
    margin-left: 10px;
}
.custom-tree-switch {
    margin-left: 4px;
}
.push_table {
    max-height: 420px;
    overflow-y: auto;
}
.more-box {
    border-top: 1px solid #ebeef5;
}
.bottom_box {
    text-align: right;
    margin-top: 26px;
}
.more-check {
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 14px;
    color: #3c7fff;
    line-height: 36px;
    text-align: center;
    cursor: pointer;
}
.el-icon-arrow-down {
    transition: 0.2s;
    line-height: 32px;
    color: #2e8ae6;
}
.arrowTransform {
    transform: rotateZ(180deg);
}

::v-deep {
    .custom-switch .el-switch__core {
        height: 17px; /* 修改高度 */
        padding: 8px; /* 修改内边距 */
        margin-bottom: 2px;
    }

    .custom-switch .el-switch__core::after {
        width: 14px; /* 修改滑块宽度 */
        height: 13px; /* 修改滑块高度 */
    }
    .custom-switch .el-switch.is-checked .el-switch__core::after {
        margin-left: -15px;
    }
    .el-descriptions .is-bordered .el-descriptions-item__cell {
        border: 1px solid #e1e3e6;
        border-bottom: none !important;
    }
    .no-brackets {
        display: none !important; /* 隐藏标签 */
    }
    .no-my-content1 {
        width: 100%;
        margin-left: 0 !important; /* 移除左边距 */
        background: #fafafa !important;
        border-right: 1px solid #e1e3e6 !important;
        padding-right: 3px !important;
        border-radius: 4px 4px 0px 0px;
    }
    .no-my-content2 {
        width: 100%;
        margin-left: 0 !important; /* 移除左边距 */
        background: #ffffff !important;
        padding-right: 3px !important;
    }
    .no-my-content3 {
        width: 100%;
        margin-left: 0 !important; /* 移除左边距 */
        background: #f9fbff !important;
        padding-right: 3px !important;
    }
    .each-brackets {
        display: inline-block;
        width: 50% !important;
        text-align: center !important;
        background: #f9fbff;
        border-right: none !important;
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 14px !important;
        color: #2b2f33 !important;
        white-space: nowrap;
        /* 确保文本不换行 */
        overflow: hidden;
        /* 超出容器部分隐藏 */
        text-overflow: ellipsis;
        /* 超出部分用省略号表示 */
        user-select: none;
    }
    .each-my-content {
        width: 50%;
        display: flex;
        justify-content: center;
        // display: inline-block;
        // text-align: center;
        background: #f9fbff !important;
    }
    .el-descriptions-row {
        display: flex;
        width: 584px !important;
    }
}

.left_no_lable1 {
    display: inline-block;
    background: #fafafa;
    border-radius: 4px 4px 0px 0px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    font-size: 14px;
    color: #2b2f33;
    width: 50%;
    text-align: center;
}
.left_no_lable2 {
    display: inline-block;
    background: #ffffff;
    border-radius: 4px 4px 0px 0px;
    font-family: Microsoft YaHei;
    font-size: 14px;
    color: #2b2f33;
    width: 50%;
    text-align: center;
}
.left_no_lable3 {
    display: inline-block;
    background: #f9fbff;
    border-radius: 4px 4px 0px 0px;
    font-family: Microsoft YaHei;
    font-size: 14px;
    color: #2b2f33;
    width: 50%;
    text-align: center;
}
.right_no_lable1 {
    display: inline-block;
    background: #fafafa;
    border-radius: 4px 4px 0px 0px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    font-size: 14px;
    color: #2b2f33;
    text-align: center;
    width: 50%;
}
.right_no_lable2 {
    display: inline-block;
    background: #ffffff;
    border-radius: 4px 4px 0px 0px;
    font-family: Microsoft YaHei;
    font-size: 14px;
    color: #2b2f33;
    text-align: center;
    width: 50%;
}
.right_no_lable3 {
    display: inline-block;
    background: #f9fbff;
    border-radius: 4px 4px 0px 0px;
    font-family: Microsoft YaHei;
    font-size: 14px;
    color: #2b2f33;
    text-align: center;
    width: 50%;
}
.right_no_lable4 {
    display: inline-block;
    background: #f9fbff;
    border-radius: 4px 4px 0px 0px;
    font-family: Microsoft YaHei;
    font-size: 14px;
    color: #2b2f33;
    text-align: center;
    width: 50%;
}
</style>
